import styled from '@emotion/styled';

export const Person = styled.div`
  align-items: center;
  display: flex;
  flex-flow: column;
  justify-content: center;
  margin-top: 60px;
  overflow-wrap: anywhere;

  ${({ theme }) => theme.MEDIA_QUERY.DESKTOP} {
    margin-top: ${({ theme }) => theme.SPACING.LG};
  }
`;

type ContainerProps = { orientation: string };

export const Container = styled.div<ContainerProps>`
  margin-top: 60px;
  text-align: center;
  width: 100%;
  ${({ theme }) => theme.MEDIA_QUERY.DESKTOP} {
    margin-top: ${({ theme }) => theme.SPACING.XL};
  }

  ${({ orientation, theme }) =>
    orientation === 'HORIZONTAL' &&
    `
    ${theme.MEDIA_QUERY.EXCLUDE_MOBILE} {
      display: grid;
      grid-template-columns: 1fr 1fr;

      ${Person}, > div {
        padding: 0 ${theme.SPACING.LG};

        &:nth-of-type(2n + 1) {
          border-right: 1px solid;
        }
        :last-of-type:nth-of-type(2n + 1) { // if there is a dangler
          border-right: none;
          grid-column-end: 3;
          grid-column-start: 1;
        }
      }
    }
  `}
`;

export const Name = styled.h2`
  margin-top: 0; // Defensive styling for bootstrap styles applied in preview
  margin-bottom: ${({ theme }) => theme.SPACING.SM};
`;

export const Role = styled.p`
  margin-bottom: ${({ theme }) => theme.SPACING.SM};
`;

export const Blurb = styled.p`
  margin-bottom: 0;
  white-space: pre-wrap;
`;

export const Headshot = styled.img`
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 50%;
  height: 150px;
  margin: 0 auto ${({ theme }) => theme.SPACING.MD};
  width: 150px;
`;

export const EmptyStateMessage = styled.p`
  text-align: center;
`;
